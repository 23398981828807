.react-confirm-alert > h1, .react-confirm-alert  {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    word-wrap: break-word;
}

#react-confirm-alert .react-confirm-alert > h1 {
    color: #000;
    margin-bottom: 10px;
}

#react-confirm-alert .react-confirm-alert h1 {
  font-size: 6vw;
}

#react-confirm-alert .react-confirm-alert {
    width:90%;
}

.btn-cancel {
    width: auto !important;
    font-size: 0.9rem !important;
    height: 2em !important;
    line-height: normal !important;
    text-transform: none !important;
    min-width: 40%;
}

#react-confirm-alert .react-confirm-alert > h3 {
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-body {
  width: auto;
  text-align: center;
  max-width: 100%;
}

#react-confirm-alert .react-confirm-alert-button-group {
justify-content: center;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
    background: #000;
    letter-spacing: 1.2px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-overlay {
    /*background: rgba(255, 255, 255, 0.3);*/
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    /*background: rgba(255, 255, 255, 0.9);*/
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: "Oswald", sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

/*.react-confirm-alert-button-group {*/
/*    display: -webkit-flex;*/
/*    display: -moz-flex;*/
/*    display: -ms-flex;*/
/*    display: -o-flex;*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    margin-top: 20px;*/
/*}*/

.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    /*padding: 6px 18px;*/
    color: #eee;
    /*margin-right: 10px;*/
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#react-confirm-alert .react-confirm-alert {
    margin: 0 1em 0 1em; }

#react-confirm-alert .react-confirm-alert h1 {
    font-family: "Oswald", sans-serif;
    color: #000000;
    margin-bottom: 10px;
    text-shadow: none;
    font-weight: 700;
}

#react-confirm-alert .react-confirm-alert-button-group {
    justify-content: center; }

#react-confirm-alert .react-confirm-alert-button-group button {
    font-size: 1.8em;
    font-weight: 800;
    height: 2em;
    width: 80%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #fff;
    background-color: #cc172c;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }

/*#react-confirm-alert .react-confirm-alert-overlay {*/
/*  background: rgba(255, 255, 255, 0.3); }*/

#react-confirm-alert .react-confirm-alert-overlay {
    z-index: 1051; }

#react-confirm-alert h3 {
    font-family: "Oswald", sans-serif;
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
    color: #000000;
    line-height: 1.3em; }
